.eye-icon {
    width: 15px;
    height: 15px;
    padding-left: 5px;
    vertical-align: middle;
  }
  
  .tooltip {
    position: absolute;
    bottom: 110%; /* Adjust to position above the image */
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 12px;
    visibility: visible;
    opacity: 0.75;
    transition: opacity 0.3s;
  }
  
  .image-container {
    position: relative;
    display: inline-block;
  }
  