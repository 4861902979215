
.tree {
  /* position: relative; */
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s ease;
  transform-origin: top;
  overflow: hidden;
}

#tree {
  transition: transform 0.2s ease;
  transform-origin: top;
  overflow: hidden;
  max-height: 75vh;
  max-width: 100vw;
}
.flowchart-container {
  transition: transform 0.2s ease;
  transform-origin: top; /* Set zoom origin to top-left */
  cursor: grab;
}

.flowchart-container.dragging {
  cursor: grabbing;
}

ul:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid rgb(109, 89, 166);
  width: 0;
  height: 30px;
}

.tree ul {
  padding-top: 30px;
  position: relative;
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

ul li li {
  padding: 30px 0px 0px;
}

.tree li {
  display: inline-block;
  position: relative;
  text-align: center;
  padding: 30px 0px 0px;
  font-size: 12px;
}

.tree li::before, .tree li::after {
  content: '';
  position: absolute;
  top: 0;
  border-top: 2px solid rgb(109, 89, 166);
  width: 50%;
  height: 30px;
  z-index: 1;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid rgb(109, 89, 166);
}


.tree .supervised li::after {
  border-left: 2px solid #AF776F;
}

.tree .supervised li::before, .tree .supervised li::after {
  border-top: 2px solid #AF776F;
}

.tree .supervised li:last-child::before {
  border-right: 2px solid #AF776F;
}

.tree .unsupervised li::after {
  border-left: 2px solid #6BE46D;
}

.tree .unsupervised li::before, .tree .unsupervised li::after {
  border-top: 2px solid #6BE46D;
}

.tree .unsupervised li:last-child::before {
  border-right: 2px solid #6BE46D;
}

.tree .reinforcement li::after {
  border-left: 2px solid #43AFA1;
}

.tree .reinforcement li::before, .tree .reinforcement li::after {
  border-top: 2px solid #43AFA1;
}

.tree .reinforcement li:last-child::before {
  border-right: 2px solid #43AFA1;
}

.tree .ensemble li::after {
  border-left: 2px solid #593D9F;
}

.tree .ensemble li::before, .tree .ensemble li::after {
  border-top: 2px solid #593D9F;
}

.tree .ensemble li:last-child::before {
  border-right: 2px solid #593D9F;
}

.tree li:only-child::after, .tree li:only-child::before {
  display: none;
}

.tree li:only-child {
  padding-top: 0;
  position: relative;
  top: -2px;
}

.tree li:first-child::before, .tree li:last-child::after {
  border: 0 none;
}

.tree li:last-child::before {
  border-right: 2px solid rgb(109, 89, 166);
  border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
}

.tree ul ul {
  position: relative;
  padding-left: 0;
  margin-left: 0;
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.arrow-bottom-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-bottom {
  margin: 0px -2px 0px 0px;
  content: '';
  width: 2px;
  transform: rotate(180deg);
  border-width: 0 6px 7px 6px;
  border-style: solid;
  z-index: 1;
}

.arrow-bottom {
  border-color: transparent transparent #6D59A6 transparent;
}

.arrow-bottom.supervised-div {
  border-color: transparent transparent #AF776F transparent;
}

.arrow-bottom-a.ensemble-div {
  border-color: transparent transparent #593D9F transparent;
}

.arrow-bottom.unsupervised-div {
  border-color: transparent transparent #6BE46D transparent;
}

.arrow-bottom.reinforcement-div {
  border-color: transparent transparent #43AFA1 transparent;
}

.flowchart-container ul ul ul {
  padding: 30px 0px 0px;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid rgb(109, 89, 166);
  width: 0;
  height: 30px;
  z-index: 1;
}

.tree ul ul.ensemble::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #593D9F;
  width: 0;
  height: 30px;
  z-index: 1;
}

.tree ul ul.supervised::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #AF776F;
  width: 0;
  height: 30px;
  z-index: 1;
}

.tree ul ul.unsupervised::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #6BE46D;
  width: 0;
  height: 30px;
  z-index: 1;
}

.tree ul ul.reinforcement::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #43AFA1;
  width: 0;
  height: 30px;
  z-index: 1;
}

.tree li a {
  padding: 6px 0;
  margin: 0px 20px;
  text-decoration: none;
  font-weight: bold;
  display: grid;
  border-radius: 5px;
  transition: all 0.5s;
  font-family: 'Roboto New', 'Public Sans', sans-serif;
  font-weight: 500;
  font-size: 20px;
  min-width: 120px;
}
@media(max-width:1640px){
  .tree li a {
    min-width: 80px;
  }
}
.tree li a.model-titel-a.a-1 {
  /* min-width: 80px; */
  width: 120px;
  margin: 0 auto;
}
.tree li a:hover {
  background: #c8e4f8;
  color: #000;
  border: 2px solid #94a0b4;
}

/* Adding root lines */
ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #000;
  height: 30px;
  z-index: 1;
}

.tree li a:hover, .tree li a:hover+ul li a {
  background: #c8e4f8; color: #000;
}
.flow-chart-div{
  overflow: hidden;
}