.modal-div {
display: grid;
grid-template-columns: auto 1fr;
padding: 10px;
color: #333;
background-color:#f4f4f4;
border-radius: 0 0 8px 8px;
}

.modal-div label {
    text-align: left;
    padding-right: 10px;
    font-family: 'Roboto', 'Public Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.modal-div span {
    font-weight: bold;
    text-align: left;
    font-family: 'Roboto New', 'Public Sans', sans-serif;
    font-size: 16px;
}

.modal-div span::before {
    content: " :";
    margin-right: 5px;
}

.details-header {
    background: #063970;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    color: #FFFFFF;
    font-weight: bold;
    font-family: 'Roboto Bold', 'Public Sans', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin: 0;
}