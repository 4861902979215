.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.search-box{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.merged-input {
  display: flex;
}
.merged-input .chart-selector {
  padding: 0;
  border-right: 0!important;
}

.merged-input .search-model {
  padding: 0;
}

.merged-input .chart-selector .custom-select {
  border-right: 0;
  border-radius: 25px 0px 0px 25px;
  padding: 10.5px 20px;
  border: 1px solid #ccc !important;
  background: linear-gradient(to right, #8a74cf, #b792d2);
  color: white;
}
.merged-input .chart-selector .option-value {
  color: black;
}

.merged-input .search-model .custom-input {
  border: 1px solid #ccc!important;
  border-left: 0!important;
  border-radius: 0px 25px 25px 0;
  outline: none!important;
  box-shadow: none!important;
  max-width: 150px;
}
.searchBy{
  text-align: left;
  padding: 6px;
}
.search-input-div{
  display: flex;
  align-items: center;
}
.first-search-box{
  padding-right: 15px;
}
.para-info{
  font-weight: 500;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.inputs-div{
  display: flex;
  justify-content: space-between;
}
.error-div {
  text-align: center;
  padding: 20px;
  color: #FF0000;
  font-family: 'Roboto', 'Public Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
