#descendant-fan-chart-container {
    text-align: center;
    text-align: -webkit-center;
    overflow: hidden;
}

#descendant-fan-chart-container {
    transition: transform 0.2s ease;
    transform-origin: top; /* Set zoom origin to top-left */
    cursor: grab;
}

#descendant-fan-chart-container.dragging {
    cursor: grabbing;
}

#descendant-fan-chart-container svg {
    display: block;
}
.fan-chart-div{
    overflow: hidden;
}