/* Header.css */
.header {
    background-color: #1e2a50;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  
  .header-btn {
    background: linear-gradient(to right, #8a74cf, #b792d2);
    border: none;
    padding: 10px 20px;
    color: white;
    font-size: 14px;
    border-radius: 30px;
    cursor: pointer;
  }
  