/* ChartSelector.css */
.chart-selector {
  font-family: Arial, sans-serif;
  padding: 10px;
}

.label {
  margin-right: 10px;
  font-size: 16px;
}

.custom-select {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 25px; 
  background-color: white;
  appearance: none;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  /* width: 250px; */
  cursor: pointer;
}

.custom-select:focus {
  outline: none;
  border-color: #8a74cf; /* Purple border on focus */
}

.custom-select option {
  padding: 10px;
  font-size: 14px;
}
.chart-select-div{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: start;
}
.dropdown-icon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333; /* Color of the triangle */
  display: inline-block;
  margin-left: 8px; /* Space between dropdown and the icon */
  vertical-align: middle; /* Aligns with text/input */
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.add-label-class{
  padding: 6px;
}