/* SearchModel.css */
.search-model {
    display: flex;
    align-items: start;
    padding: 10px;
    flex-direction: column;
  }
  
  .label {
    margin-right: 10px;
    font-size: 16px;
  }
  
  .custom-input {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 25px;
    width: 250px;
    transition: border-color 0.3s ease;
  }
  
  .custom-input:focus {
    outline: none;
    border-color: #8a74cf;
  }
  label.add-class {
    padding: 6px;
  }
  