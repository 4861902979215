.arrow-details-div {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 9;
    border: 1px solid #E0E0E0;
    border-radius: 8px; 
}
.arrow-details-div .arrow-div{
    display: flex;
    align-items: center;
    padding: 10px 20px;
}
.arrow-details-div .arrow-div-span{
    font-family: 'Roboto', 'Public Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
}
.arrow-details-div .arrow-svg {
    margin-right: 15px;
}